import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { logout as logoutUserAction } from 'store/user/userActions';

import { QFF_AUTH_API_URL, LSL_AUTH_API_URL } from 'config';
import { captureErrorInSentry } from 'lib/errors';

import type { Dispatch } from '@reduxjs/toolkit';

export const getLogoffQFF = () => fetch(`${LSL_AUTH_API_URL.slice(0, -6)}/logoff`, { credentials: 'include' });

export const logout = async (dispatch: Dispatch) => {
  try {
    const qh = fetch(`${QFF_AUTH_API_URL}/logout`, { method: 'POST', credentials: 'include' });
    const qa = getLogoffQFF();
    await Promise.all([qh, qa]);
  } catch (error) {
    captureErrorInSentry(error);
  }
  dispatch(logoutUserAction());
};

export const useLogout = () => {
  const dispatch = useDispatch();

  const logoutSSO = useCallback(() => {
    logout(dispatch);
  }, [dispatch]);

  return { logout: logoutSSO };
};

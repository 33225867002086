export const ACCOUNT_MANAGEMENT_TYPES = {
  APP_WIDE: 'app_wide',
  CHECKOUT_ONLY: 'checkout_only',
};

// QH specific features
export const SIMPLICITY_HEADER_ENABLED = false;
export const CLASSIC_OFFERS_ENABLED = false;
export const POINTS_EARN_ENABLED = false;
export const DEPOSIT_PAY_ENABLED = false;
export const PAYWITH_TOGGLE_ENABLED = false;
export const CAMPAIGN_BANNER_ENABLED = false;
export const DREAM_PLANER_ENABLED = false;
export const SELF_SERVICE_ENABLED = false;
export const PERSONALISE_TRACKING_ENABLED = false;
export const QFF_ACCOUNT_MANAGEMENT = ACCOUNT_MANAGEMENT_TYPES.CHECKOUT_ONLY;
export const WELCOME_MESSAGE_ENABLED = false;
export const FLIGHT_BOOKER_MESSAGE_ENABLED = false;
export const OAUTH_ENABLED = true;
export const EXCLUSIVE_OFFERS_ENABLED = false;
export const POINTS_REDEMPTION_ENABLED = false;
export const QTA_ENABLED = false;
export const CAMPAIGNS_ENABLED = false;
export const TRAVEL_INSURANCE_CROSS_SELL_ENABLED = false;
export const MULTI_ROOM_MESSAGING = false;
export const DESTINATION_PAGES_ENABLED = false;

import { EventType, createEvent } from '@qantasexperiences/analytics';
import { selectItem } from 'store/property/propertyActions';

const emitSelectItem = ({ payload }) => {
  return createEvent({
    type: EventType.SELECT_ITEM,
    payload: {
      id: payload.listName,
      listName: payload.listName,
      location: payload.location,
      type: payload.type,
      category: payload.category,
      payWith: payload.payWith,
      availableProperties: payload.availableProperties,
      availableRooms: payload.availableRooms,
      query: payload.query,
      property: payload.property,
      offer: payload.offer,
      roomType: payload.roomType,
      pointsConversion: payload.pointsConversion,
    },
  });
};

// eslint-disable-next-line
// @ts-ignore
export default { [selectItem]: emitSelectItem };

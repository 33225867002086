import { createSelector } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-named-as-default
import Decimal from 'decimal.js';
import { getReservation, getBookingId, getBookingTotal, getPayableLaterCashAmount } from 'store/booking/bookingSelectors';
import { getBookingChannel } from 'store/checkout/checkoutSelectors';

import {
  getCommissionValue,
  getDomesticOrInternational,
  getStateCodeFromStateName,
  getNumNights,
  getTransactionPointsPlusPay,
} from 'lib/datalayer/helpers';

const getOfferDetails = ({ id, type, promotion }) => ({
  offerId: id,
  offerType: type,
  // These keys are pluralised so that they align with the variables from the property page in GA
  promotionNames: promotion?.name ?? 'none',
  promotionCodes: promotion?.description ?? 'none',
});

const getPropertyDetails = ({ address, id, name }) => {
  const { country, state: stateName, suburb } = address;
  const domesticOrInternational = getDomesticOrInternational(country);

  return {
    domesticOrInternational,
    propertyName: name,
    propertyId: id,
    propertyCountryName: country,
    propertyStateCode: getStateCodeFromStateName(stateName),
    propertyCity: suburb,
  };
};

const getTransactionDetails = (reservation, bookingTotal) => {
  const transactionTotal = reservation.offer.charges.total.amount;

  const { checkIn, checkOut } = reservation;
  const numNights = getNumNights(checkIn, checkOut);
  const averageNightlyRate = new Decimal(transactionTotal).dividedBy(numNights).toFixed(2);

  const transactionCash = new Decimal(bookingTotal?.creditCard?.total ?? '0.0');
  const transactionPoints = new Decimal(bookingTotal?.points?.totalPoints ?? '0.0');
  const transactionVoucher = new Decimal(bookingTotal?.voucher?.total ?? '0.0');

  const transactionIncludesVoucher = !transactionVoucher.isZero();
  const transactionPointsPlusPay = getTransactionPointsPlusPay(transactionCash, transactionPoints);

  return {
    transactionPoints: transactionPoints.toString(),
    transactionTotal,
    transactionPointsPlusPay,
    transactionIncludesVoucher,
    averageNightlyRate,
  };
};

export const getConfirmationPageDataLayerVariables = createSelector(
  getBookingId,
  getReservation,
  getBookingTotal,
  getBookingChannel,
  getPayableLaterCashAmount,
  (bookingId, reservation, bookingTotal, bookingChannel, payableLaterCashAmount) => {
    // If the reservation exists on the confirmation page, it means that we have transitioned from
    // a successful booking and we should track the details in GTM. If it doesn't exist, it means
    // the confirmation page has been reloaded and we don't want to track the booking twice.
    if (!reservation) return { ready: true };

    const transactionTotal = reservation.offer.charges.payableAtBooking.total.amount;
    const country = reservation.property.address.country;

    const offerDetails = getOfferDetails(reservation?.offer);
    const propertyDetails = getPropertyDetails(reservation?.property);
    const transactionDetails = getTransactionDetails(reservation, bookingTotal);

    return {
      ready: true,
      bookingId,
      checkIn: reservation.checkIn,
      checkOut: reservation.checkOut,
      roomNights: getNumNights(reservation.checkIn, reservation.checkOut),
      numAdults: reservation.adults,
      numChildren: reservation.children,
      numInfants: reservation.infants,
      roomType: reservation?.roomType?.name,
      includesAbn: !!reservation?.travelArranger?.businessRegistrationNumber,
      commission: getCommissionValue(transactionTotal, country),
      ...offerDetails,
      ...propertyDetails,
      ...transactionDetails,
      bookingChannel,
      isDepositPay: payableLaterCashAmount.greaterThan(0),
    };
  },
);

import React from 'react';
import PropTypes from 'prop-types';
import { themeGet } from 'styled-system';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import noop from 'lodash/noop';
import includes from 'lodash/includes';
import { Flex, Box, Icon, NakedButton } from '@qga/roo-ui/components';
import { HEADER, ANIMATION_DURATION } from './constants';
import { mediaQuery } from 'lib/styledSystem';

const HeaderWrapper = styled(Box)`
  ${({ transitionState }) =>
    includes(['exited'], transitionState) &&
    css`
      display: none;
    `};
`;

const StyledNakedButton = styled(NakedButton)`
  color: ${themeGet('colors.greys.charcoal')};
  transform: scale(0);
  background-color: ${themeGet('colors.white')};
  border-radius: 100%;
  height: ${HEADER}px;
  width: ${HEADER}px;
  margin-right: -${themeGet('space.4')};
  position: absolute;
  top: 0;
  right: 0;
  transition: transform 150ms ease-in;

  ${({ transitionState }) =>
    includes(['entering', 'entered'], transitionState) &&
    css`
      transform: scale(1);
    `};
`;
StyledNakedButton.displayName = 'StyledNakedButton';
StyledNakedButton.defaultProps = { ...NakedButton.defaultProps };

const HeaderContainer = styled(Flex)`
  ${mediaQuery.mobileOnlyEscapeHatch} {
    background-color: ${themeGet('colors.white')};
    margin: -${themeGet('space.4')};
    margin-bottom: ${themeGet('space.4')};
    justify-content: space-between;
    align-content: center;
    position: relative;
    opacity: 0;
    height: ${HEADER}px;
    transition: opacity ${ANIMATION_DURATION}ms ease-in;

    ${({ transitionState }) =>
      includes(['entering', 'entered'], transitionState) &&
      css`
        opacity: 1;
      `};
  }
`;

HeaderContainer.displayName = 'HeaderContainer';

const UpdatedHeaderContainer = styled(HeaderContainer)`
  align-items: center;
  box-shadow: 0px 1px 0px #d9d9d9;
`;

const Title = styled(Flex)`
  font-size: ${themeGet('fontSizes.md')};
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
`;

const UpdatedTitle = styled(Title)`
  display: block;

  font-family: 'Jetstar';
  font-size: ${themeGet('fontSizes.md')};
  line-height: 1.25;
  font-weight: bold;
  text-align: center;

  width: 100%;
`;

const Header = ({ title, transitionState, cancelModal }) => {
  return (
    <HeaderWrapper position="relative" transitionState={transitionState}>
      <UpdatedHeaderContainer transitionState={transitionState}>
        {title && (
          <UpdatedTitle as="div" data-testid="modal-title">
            {title}
          </UpdatedTitle>
        )}
      </UpdatedHeaderContainer>
      <StyledNakedButton onClick={cancelModal} data-testid="close-modal" transitionState={transitionState}>
        <Icon name="close" />
      </StyledNakedButton>
    </HeaderWrapper>
  );
};

Header.propTypes = {
  transitionState: PropTypes.string.isRequired,
  title: PropTypes.string,
  cancelModal: PropTypes.func,
};

Header.defaultProps = {
  title: '',
  cancelModal: noop,
};

export default Header;

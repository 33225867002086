// eslint-disable-next-line import/no-named-as-default
import Decimal from 'decimal.js';
import { trackBooking } from 'store/booking/bookingActions';
import { getDomesticOrInternational, getStateCodeFromStateName, getNumNights } from './helpers';

const emitTransReadyEvent = ({ payload }) => {
  if (!payload?.reservation) return null;

  const { id, reservation } = payload;
  const address = reservation && reservation.property && reservation.property.address;
  const { suburb, state, country } = address || {};

  const totalCharges = reservation?.offer?.charges?.total?.amount;
  const numNights = getNumNights(reservation?.checkIn, reservation?.checkOut);
  const averageNightlyRate = new Decimal(totalCharges).dividedBy(numNights).toFixed(2);

  return {
    event: 'transReady',
    transactionId: id,
    transactionAffiliation: getDomesticOrInternational(country),
    transactionTotal: totalCharges,
    transactionShipping: undefined, // Our product does not fit the normal context for how this variables would be used, but Glenn has asked they be included anyway
    transactionProducts: [
      {
        sku: reservation?.offer?.id,
        name: `${reservation?.property?.id} | ${reservation?.property?.name}`,
        category: `${country} | ${getStateCodeFromStateName(state)} | ${suburb}`,
        price: averageNightlyRate,
        quantity: getNumNights(reservation?.checkIn, reservation?.checkOut),
      },
    ],
  };
};

export default { [trackBooking]: emitTransReadyEvent };

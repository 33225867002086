import { beginCheckout } from 'store/checkout/checkoutActions';
import { EventType, createEvent } from '@qantasexperiences/analytics';

const emitBeginCheckout = ({ payload }) => {
  return createEvent({
    type: EventType.BEGIN_CHECKOUT,
    payload: {
      quote: payload.quote,
      payments: payload.payments,
      pointsConversion: payload.pointsConversion,
      isRebooked: payload.isRebooked,
    },
  });
};

// eslint-disable-next-line
// @ts-ignore
export default { [beginCheckout]: emitBeginCheckout };

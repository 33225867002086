/* eslint-disable prettier/prettier */
import React from 'react';
import Head from 'next/head';
import { useSelector } from 'react-redux';
import { getHomePageMeta } from 'store/homePage/homePageSelectors';
import { HOTELS_URL } from 'config';

type Props = {
  title?: string;
  description?: string;
  tags?: {
    content: string;
    name: string;
  }[];
  targetRef?: React.RefObject<HTMLElement>;
};

// Double quotes are necessary for the schema to be valid
const SCHEMA = {
  '@context': 'https://schema.org',
  '@type': 'Organization',
  name: 'Jetstar Hotels',
  url: 'https://www.jetstar.com/hotels',
  logo: 'https://www.jetstar.com/content/dam/qbr/partners/logos/qantas_hotels.svg',
  contactPoint: {
    '@type': 'ContactPoint',
    telephone: '1300 738 206',
    contactType: 'customer service',
    contactOption: 'TollFree',
    areaServed: 'AU',
    availableLanguage: 'en',
  },
  sameAs: ['https://www.instagram.com/Jetstar/', 'https://www.youtube.com/user/Jetstar', 'https://www.facebook.com/Jetstar/'],
};

const HomePageHelmet = () => {
  const { title = 'Jetstar Hotels', description, tags = [] }: Props = useSelector(getHomePageMeta);
  return (
    <Head>
      <title>{title}</title>
      <link rel="canonical" href={HOTELS_URL} />
      {description && <meta name="description" content={description} />}
      {tags && tags.map(({ name, content }) => <meta key={name} name={name} content={content} />)}

      <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(SCHEMA) }} />
    </Head>
  );
};

export default HomePageHelmet;

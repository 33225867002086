import { HOTELS_PATH } from 'config/env';

export const COOKIE_PATHS = {
  GLOBAL: '/',
  HOTELS: HOTELS_PATH,
  SEARCH: `${HOTELS_PATH}/search`,
  LIST_SEARCH: `${HOTELS_PATH}/search/list`,
  MAP_SEARCH: `${HOTELS_PATH}/search/map`,
};

export const COOKIE_NAMES = {
  FLIGHT_BOOKER: 'flight_booker',
  FLIGHTBOOKINGS_GUID: 'flightbookings_guid',
  HIDE_SEARCH_MESSAGING: 'qh_hide_search_messaging',
  ACCESS_TOKEN: 'lsl_auth_data',
  POINTS_CHANGE_NOTIFICATION: 'points_change_notice',
  QH_USER_ID: 'qh_user_id',
  PBE_USER_ID: 'pbe_user_id',
  METASEARCH: 'metasearch',
  TRIP_ADVISOR_REF_ID: 'trip_advisor_ref_id', // this cookie is used to correlate users from TripAdvisor after checkout
  TRIVAGO_TRV_REFERENCE: 'trivago_trv_reference', // this cookie is used to correlate users from trivago after checkout
  CHECK_IN_DATE: 'qh_check_in',
  CHECK_OUT_DATE: 'qh_check_out',
  HIDE_LOGIN_TOOLTIP: 'qh_hide_login_tooltip',
  HIDE_WELCOME_MESSAGE: 'qh_hide_welcome_message',
  QH_AUTH_MEMBER_ID: 'lsl_user_id',
  QL_WL_SESSION: 'qlff_wl_sess',
};

export const COOKIE_MAX_AGES = {
  ONE_DAY_IN_SECONDS: 24 * 60 * 60,
  TWO_DAYS_IN_SECONDS: 2 * 24 * 60 * 60,
  SEVEN_DAYS_IN_SECONDS: 7 * 24 * 60 * 60,
  THIRTY_DAYS_IN_SECONDS: 30 * 24 * 60 * 60,
  ONE_YEAR_IN_SECONDS: 365 * 24 * 60 * 60,
};

export const BOT_USER_ID = 'bot';

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useCombobox } from 'downshift';
import { Box, Text, Icon } from '@qga/roo-ui/components';
import noop from 'lodash/noop';
import { Label, LabelText } from 'components/Label';
import TextHighlighter from 'components/TextHighlighter';
import ResponsiveModal from 'components/ResponsiveModal';
import { useLocationData } from './useLocationData';
import { UpdatedAutocompleteInput, UpdatedResultItem, UpdatedResultList } from './primitives';

const PLACEHOLDER_TEXT = 'Destination, city, hotel name';

const isLocation = (item) => item?.type === 'location';
const isProperty = (item) => item?.type === 'property';

const itemToString = (item) => item?.fullName ?? '';

const stateReducer = (state, actionAndChanges) => {
  const { type, changes } = actionAndChanges;

  switch (type) {
    case useCombobox.stateChangeTypes.InputBlur:
    case useCombobox.stateChangeTypes.InputKeyDownEscape:
      return { ...changes, inputValue: '' };
    default:
      return changes;
  }
};

const LocationAutocompleter = ({
  title,
  label,
  locationName,
  labelOptions,
  routeToProperty,
  updateQuery,
  placeholder,
  error,
  returnProperties,
  isOptional,
  limit,
}) => {
  const [items, setItems] = useState([]);
  const [isFocused, setIsFocused] = useState(false);

  const truncateText = (text, limit) => {
    if (limit) return text?.length > limit ? text.substring(0, limit - 1) + '...' : text;
    return text;
  };

  /* Because we are combining render props with hooks, we need to store these functions as refs so they are defined in the hook and assigned in the render */
  const blurModalRef = useRef(noop);
  const openModalRef = useRef(noop);
  const inputRef = useRef();

  const onSelectedItemChange = useCallback(
    ({ selectedItem }) => {
      blurModalRef.current();
      inputRef.current.blur();
      // Clear subregions when the location is changed - don't want to apply Melbourne subregions to a Brisbane location search
      if (isLocation(selectedItem)) updateQuery({ location: selectedItem.fullName, subRegions: undefined });
      if (isProperty(selectedItem))
        routeToProperty({ id: selectedItem.id, propertyName: selectedItem.fullName, excludeParams: ['location', 'propertyName'] });
    },
    [routeToProperty, updateQuery],
  );

  const { isOpen, highlightedIndex, getLabelProps, getInputProps, getMenuProps, getItemProps, inputValue, setInputValue } = useCombobox({
    items,
    itemToString,
    stateReducer,
    onSelectedItemChange,
    defaultHighlightedIndex: 0,
    inputId: 'location-search-input',
    labelId: 'location-search-label',
  });

  const results = useLocationData({ locationName: inputValue, returnProperties });
  useEffect(() => {
    setItems(results);
  }, [results]);

  const onFocus = useCallback(() => {
    openModalRef.current();
    setInputValue('');
    setIsFocused(true);
  }, [setInputValue]);

  const onBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  const hasItems = useMemo(() => results.length > 0 || (results.length === 0 && inputValue?.length >= 3), [results.length, inputValue]);

  return (
    <ResponsiveModal title={title}>
      {({ openModal, blurModal }) => {
        blurModalRef.current = blurModal;
        openModalRef.current = openModal;
        return (
          <Box position="relative">
            <Label {...getLabelProps()}>
              <LabelText {...labelOptions} hidden={[true, false]}>
                {label}
              </LabelText>
              <Box>
                <UpdatedAutocompleteInput
                  data-testid="location-search-input"
                  error={error}
                  {...getInputProps({
                    ref: inputRef,
                    left: 2,
                    borderRadius: 'default',
                    border: 2,
                    placeholder: isFocused ? placeholder : truncateText(locationName, limit) || placeholder,
                    icon: 'place',
                    isFocused,
                    onFocus,
                    onBlur,
                    suffix: isOptional ? 'Optional' : '',
                  })}
                />
              </Box>
            </Label>
            <UpdatedResultList {...getMenuProps({ isOpen, hasItems })}>
              {results.map((item, index) => (
                <UpdatedResultItem
                  key={index}
                  data-testid="location-search-result"
                  {...getItemProps({
                    index,
                    item,
                    highlighted: highlightedIndex === index,
                  })}
                >
                  {isLocation(item) && <Icon name="place" />}
                  {isProperty(item) && <Icon name="localHotel" />}
                  <Text as="div" fontSize="sm" lineHeight={1.5} color="greys.charcoal">
                    <TextHighlighter text={item.fullName} highlightText={inputValue} />
                  </Text>
                </UpdatedResultItem>
              ))}
              {hasItems && results.length === 0 && (
                <UpdatedResultItem data-testid="location-search-result" style={{ cursor: 'default', pointerEvents: 'none' }}>
                  <Icon name="error" />
                  <Text as="div" fontSize="sm" lineHeight={1.5} color="greys.charcoal">
                    <TextHighlighter text="We cannot find a matching location" />
                  </Text>
                </UpdatedResultItem>
              )}
            </UpdatedResultList>
          </Box>
        );
      }}
    </ResponsiveModal>
  );
};

LocationAutocompleter.propTypes = {
  title: PropTypes.string,
  label: PropTypes.string,
  locationName: PropTypes.string,
  labelOptions: PropTypes.object,
  updateQuery: PropTypes.func.isRequired,
  routeToProperty: PropTypes.func,
  placeholder: PropTypes.string,
  error: PropTypes.bool,
  returnProperties: PropTypes.bool,
  isOptional: PropTypes.bool,
  limit: PropTypes.number,
};

LocationAutocompleter.defaultProps = {
  title: 'Find destination or hotel',
  label: 'Destination or hotel name',
  locationName: '',
  labelOptions: {},
  placeholder: PLACEHOLDER_TEXT,
  routeToProperty: () => {},
  error: false,
  returnProperties: true,
  isOptional: false,
  limit: null,
};

export default LocationAutocompleter;

import React, { useRef, useEffect, useState, useCallback, Fragment } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import isFunction from 'lodash/isFunction';
import noop from 'lodash/noop';
import Header from './Header';
import Footer from './Footer';
import { Hide, Box } from '@qga/roo-ui/components';
import FocusManager from 'components/FocusManager';
import { isIOSDevice, fixBodyScroll, unfixBodyScroll, isPhone } from 'lib/browser';
import { ANIMATION_DURATION } from './constants';
import { useTransition } from 'hooks/useTransition';
import { ResponsiveContainer, Body, Inner } from './primitives';
import { getIsMobileApp } from 'store/ui/uiSelectors';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { themeGet } from 'styled-system';

const ESC = 27;

const MobileAppBackground = styled(Box)`
  ${(props) =>
    props.isMobileApp &&
    props.transitionState === 'entered' &&
    css`
      position: fixed;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      background: rgba(34, 34, 34, 0.8);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: ${themeGet('zIndices.mobileModal')(props)};
    `};
`;

const ResponsiveModal = React.memo(
  ({ title, children, onOpenModal, onSubmit, onCancel, onBlur, submitButtonText, enableFooter, enableHeader, isSubmitOnCancel }) => {
    const scrollRef = useRef();
    const [transitionToOpen, setTransitionToOpen] = useState(false);
    const transitionState = useTransition({ transition: transitionToOpen, duration: ANIMATION_DURATION });

    const isMobileApp = useSelector(getIsMobileApp);

    // NOTE: we derive isOpen here, so children do not render one frame before the container
    const isOpen = transitionState !== 'exited';

    const displayFooter = enableFooter && (onSubmit !== noop || submitButtonText);

    useEffect(() => {
      if (transitionState === 'entering') {
        if (isFunction(onOpenModal)) onOpenModal();
        if (isPhone()) fixBodyScroll();
        // scroll to force the modal to lock into it's fixed position if a field is focused after the keyboard is opened
        // 100px ensures that we get past the address bar if it wasn't active and gets forced into view
        if (isPhone() && isIOSDevice()) window.scrollBy(0, -100);
      }

      if (transitionState === 'exited') {
        if (isPhone()) unfixBodyScroll();
      }
    }, [onOpenModal, transitionState]);

    useEffect(() => {
      return () => unfixBodyScroll();
    }, []);

    const openModal = useCallback(() => {
      setTransitionToOpen(true);
    }, []);

    const closeModal = useCallback(() => {
      setTransitionToOpen(false);
    }, []);

    const blurModal = useCallback(() => {
      if (!transitionToOpen) return;
      closeModal();
      onBlur();
    }, [closeModal, onBlur, transitionToOpen]);

    const submitModal = useCallback(() => {
      closeModal();
      onSubmit();
    }, [closeModal, onSubmit]);

    const cancelModal = useCallback(() => {
      closeModal();
      onCancel();
    }, [closeModal, onCancel]);

    useEffect(() => {
      const handleKeyUp = (event) => {
        if (event.keyCode !== ESC) return;
        if (document.activeElement) document.activeElement.blur();
        cancelModal();
      };
      document.addEventListener('keyup', handleKeyUp);

      return () => {
        document.removeEventListener('keyup', handleKeyUp);
      };
    }, [cancelModal]);

    return (
      <Fragment>
        {transitionState === 'entered' && <MobileAppBackground transitionState={transitionState} isMobileApp={isMobileApp} />}
        <ResponsiveContainer transitionState={transitionState} isMobileApp={isMobileApp} hasUpdatedHeaderNav>
          <FocusManager onBlur={blurModal}>
            {enableHeader && (
              <Hide sm md lg>
                <Header title={title} transitionState={transitionState} cancelModal={isSubmitOnCancel ? submitModal : cancelModal} />
              </Hide>
            )}
            <Body transitionState={transitionState} ref={scrollRef} enableHeader={enableHeader} enableFooter={enableFooter}>
              <Inner transitionState={transitionState} data-testid="modal-content" data-transition-state={transitionState}>
                {children({ isOpen, openModal, submitModal, blurModal, cancelModal })}
              </Inner>
            </Body>
            {displayFooter && (
              <Hide sm md lg>
                <Footer
                  buttonText={submitButtonText}
                  isMobileApp={isMobileApp}
                  submitModal={submitModal}
                  transitionState={transitionState}
                />
              </Hide>
            )}
          </FocusManager>
        </ResponsiveContainer>
      </Fragment>
    );
  },
);

ResponsiveModal.displayName = 'ResponsiveModal';

ResponsiveModal.propTypes = {
  title: PropTypes.string,
  children: PropTypes.func.isRequired,
  onOpenModal: PropTypes.func,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  onBlur: PropTypes.func,
  submitButtonText: PropTypes.string,
  enableFooter: PropTypes.bool,
  enableHeader: PropTypes.bool,
  isSubmitOnCancel: PropTypes.bool,
};

ResponsiveModal.defaultProps = {
  title: '',
  onOpenModal: noop,
  onSubmit: noop,
  onCancel: noop,
  onBlur: noop,
  submitButtonText: undefined,
  enableFooter: true,
  enableHeader: true,
  isSubmitOnCancel: false,
};

export default ResponsiveModal;

import { trackBooking } from 'store/booking/bookingActions';
import { EventType, createEvent } from '@qantasexperiences/analytics';

const emitBookingConfirmationEvent = ({ payload }) => {
  if (!payload?.booking?.reservation) return null;

  return createEvent({
    type: EventType.PURCHASE,
    payload: {
      booking: payload.booking,
      quote: payload.quote,
      isRebooked: payload.isRebooked,
    },
  });
};

// eslint-disable-next-line
// @ts-ignore
export default { [trackBooking]: emitBookingConfirmationEvent };

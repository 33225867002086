import axios, { AxiosRequestConfig } from 'axios';
import { isServer } from 'lib/renderHelpers';
import isObject from 'lodash/isObject';
const DEFAULT_TIMEOUT = 30000;

interface WrappedAxiosRequestConfig extends AxiosRequestConfig {
  accessToken?: string;
  qhUserId?: string;
  cookie?: string | Record<string, string>;
  headers?: Record<string, string>;
}

interface HttpError extends Error {
  response?: {
    status: number;
  };
}

const createMockHttpError = (response) => {
  const error: HttpError = new Error(`Api returned data of type ${typeof response.data} instead of json`);
  error.response = { status: 500 };
  return error;
};

const wrappedAxios = async ({ accessToken, qhUserId, cookie, headers: incomingHeaders, ...options }: WrappedAxiosRequestConfig) => {
  const headers: WrappedAxiosRequestConfig['headers'] = {};

  if (accessToken) {
    headers.Pragma = 'no-cache';
    headers.Authorization = `Bearer ${accessToken}`;
  }

  if (isServer && typeof cookie !== 'undefined') {
    if (typeof cookie === 'string') headers.cookie = cookie;
    else if (typeof cookie === 'object')
      headers.cookie = Object.keys(cookie)
        .map((key) => `${key}=${encodeURIComponent(cookie[key])};`)
        .join(' ');
  }

  if (qhUserId) {
    headers['qh-meta'] = JSON.stringify({
      qhUserId,
    });
  }

  if (incomingHeaders) {
    for (const [key, value] of Object.entries(incomingHeaders)) {
      headers[key] = value;
    }
  }

  const response = await axios({ headers, timeout: DEFAULT_TIMEOUT, ...options });

  if (!isObject(response.data)) {
    throw createMockHttpError(response);
  }

  return response;
};
export default wrappedAxios;

import * as campaignsClient from 'lib/clients/fetchCampaign';
import { fetchCampaign, setCampaign, clearCampaign } from './campaignActions';
import isEmpty from 'lodash/isEmpty';
import { createAsyncLogic } from 'lib/logic';
import { getOptimizely } from 'lib/optimizely/optimizely';

const fetchCampaignLogic = createAsyncLogic({
  type: fetchCampaign,
  latest: true,
  async process(_, dispatch) {
    const campaign = await campaignsClient.fetchCampaign();
    const optimizely = await getOptimizely();
    const isLocalCampaignEnabled = optimizely.isFeatureEnabled('jetstar-hotels-blue-banner-message');
    const localCampaignConfig = optimizely.getAllFeatureVariables('jetstar-hotels-blue-banner-message');

    if (!isEmpty(campaign)) {
      dispatch(setCampaign(campaign));
    } else {
      if (isLocalCampaignEnabled && !isEmpty(localCampaignConfig)) {
        dispatch(setCampaign(localCampaignConfig));
      } else {
        dispatch(clearCampaign());
      }
    }
  },
  onError(context, dispatch) {
    dispatch(clearCampaign());
  },
});

export const logic = [fetchCampaignLogic];

import { HOTELS_URL, LUXE_HOST, HOLIDAY_PACKAGES_HOST, AUTH_API_URL, LSL_AUTH_URL, IS_SERVER, IS_DEVELOPMENT } from './env';
import { jetstarBestDeal, jetstarChainDeal, jetstarLuxeOffer, jetstarSearch } from '@qga/roo-ui/assets';

// QH support numbers
export const PARTNER_SERVICES_PHONE_NUMBER = '1300 558 610';
export const LIVE_CHAT_OPERATING_TIMES = '8:30am - 5:30pm (AEDT/AEST)';
export const PHONE_SUPPORT_OPERATING_TIMES = '8:30am - 5:30pm (AEDT/AEST)';

export const TRIP_ADVISOR_IMG_BRANDING_URL =
  'https://www.tripadvisor.com/img/cdsi/img2/branding/v2/Tripadvisor_lockup_horizontal_secondary_registered-15969-0.svg';

// Internal links
export const CONTACT_US_LIVE_CHAT = '/contact-us#live-chat';
export const CONTACT_US_FORM = '/contact-us/email';
export const FAQS = '/faqs';

// Internal URLs (used for print views)
export const QH_CONTACT_US_URL = `${HOTELS_URL}/contact-us`;
export const QH_FAQ_URL = `${HOTELS_URL}${FAQS}`;
export const AIRBNB_TAB_URL = `${HOTELS_URL}?searchType=airbnb`;
export const AIRBNB_INTERSTITIAL_PAGE_URL = `${HOTELS_URL}/airbnb/redirect`;

// External urls
export const HOTELS_LANDING_PAGE_URL = 'https://www.jetstar.com/au/en/hotels/';
export const QCOM_PRICE_PROMISE_URL = 'https://www.qantas.com/au/en/travel/airlines/price-promise/global/en';
export const QCOM_TERMS_AND_CONDITIONS_URL = 'https://www.qantas.com/au/en/book-a-trip/hotels-and-airbnb/terms-and-conditions.html';

export const AIRBNB_JOIN_QFF_URL = 'https://www.qantas.com/au/en/frequent-flyer/discover-and-join/join-now.html/code/AIRBNB';

export const QFF_MY_ACCOUNT_URL = 'https://www.qantas.com/au/en/frequent-flyer/my-account.html';
export const QFF_MY_PROFILE_URL = 'https://www.qantas.com/au/en/frequent-flyer/my-account/profile.html';
export const QFF_MY_POINTS_ACTIVITY_URL = 'https://www.qantas.com/au/en/frequent-flyer/my-account/points-activity.html';

export const QFF_SIGNUP_URL = 'https://www.qantas.com/fflyer/dyns/joinff';

export const HOTEL_DEALS_URL = `${HOTELS_URL}/deals`;
export const LUXE_URL = LUXE_HOST;
export const BRAND_HOLIDAYS_URL = HOLIDAY_PACKAGES_HOST;
export const BRAND_SELF_SERVICE_URL = `${HOTELS_URL}/manage/bookings`;
export const BRAND_TERMS_AND_CONDITIONS_URL = 'https://www.jetstar.com/au/en/hotels/terms-and-conditions';

export const QH_DREAM_PLANNER_URL = 'https://www.qantas.com/au/en/frequent-flyer/my-account/dream-planner.html';

export const QCOM_TRAVELPASS_TERMS_URL = 'https://www.qantas.com/au/en/book-a-trip/flights/payment-options/travelpass.html#conditions';
export const QCOM_TRAVELPASS_BALANCE_URL = 'https://www.qantas.com/quisportal/';

export const JETSTAR_LANDING_PAGE_URL = 'https://www.jetstar.com/au/en/hotels/';
export const POINTS_PER_DOLLAR_DEFAULT = 3;
export const QFF_AUTH_API_URL =
  !IS_DEVELOPMENT && !IS_SERVER ? `${window.location.origin}/au/en/hotels/auth` : `${AUTH_API_URL}/au/en/hotels/auth`;
export const LSL_AUTH_API_URL = LSL_AUTH_URL;

export const QL_TRAVEL_INSURANCE_POLICIES = 'https://travel.insurance.qantas.com/au/policies';
export const QL_NZ_TRAVEL_INSURANCE_POLICIES = 'https://travel.insurance.qantas.com/nz/policies';

export const E2E_TIMEOUT = 10000;
export const QTA_SCREENSIZES = ['iphone-x'];

export const MULTI_ROOM_BOOKING_PROMPT_MESSAGE = 'For group bookings and multiple room reservations call ';
export const REQUEST_CALLBACK_PHONE_NUMBER = '13 70 66';

export const Points_Earn_Tier_Instance_Family = 'd5fc7d01-38cb-4b30-a322-d21002fe7b00';

export const VPP_TERMS_CONDITIONS = `± 30% better value is based on the value of a Point when using Points Plus Pay for Jetstar Hotels bookings through jetstar.com/hotels as compared to the value of a Point using Points Plus Pay for Jetstar Hotel bookings made prior to February 2022. 30% better value only applies to the points component of the booking.`;

export const DEFAULT_DEAL_TYPE_ORDER = {
  all_deals: 0,
  best_deals: 1,
  luxury_offers: 2,
  chain_deals: 3,
};

export const DEFAULT_DEAL_TYPE = {
  code: 'all_deals',
  name: 'All deals',
  slug: '',
  description: 'Great deals for you!',
};

export const ALLOWED_DEAL_TYPES = [
  {
    code: 'best_deals',
    name: 'Best deals',
    slug: 'best-deals',
    icon: jetstarBestDeal,
    description: 'A range of great deals based on sales & popularity',
  },
  {
    code: 'luxury_offers',
    name: 'Luxury offers',
    slug: 'luxury-offers',
    icon: jetstarLuxeOffer,
    description: 'Packed with great savings and premium inclusions',
  },
  {
    code: 'chain_deals',
    name: 'Chain deals',
    slug: 'chain-deals',
    icon: jetstarChainDeal,
    description: 'Get member deals at popular chains',
  },
];

export const GO_TO_SEARCH_BANNER = {
  icon: { src: jetstarSearch, alt: 'search icon' },
  cta: {
    label: 'Search all hotels',
  },
};

export const ALL_DEALS_RESULTS_LIMIT = 4;
export const DEAL_TYPE_RESULTS_LIMIT = 20;

export const EXCLUSIVE_OFFERS_CROSS_SELL_INTERVAL = 60;
export const EXCLUSIVE_OFFERS_CROSS_SELL_LIMIT = 3;

export const RATING_TOOLTIP_MESSAGES = {
  AAA: 'This official star rating is provided by the property, verified by an independent third party.',
  SELF_RATED: 'This is a self assigned rating by the service provider or obtained through Expedia.',
};

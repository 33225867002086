import { addPaymentInfo } from 'store/checkout/checkoutActions';
import { EventType, createEvent } from '@qantasexperiences/analytics';

const emitAddPaymentInfo = ({ payload }) => {
  return createEvent({
    type: EventType.ADD_PAYMENT_INFO,
    payload: {
      quote: payload.quote,
      payments: payload.payments,
      pointsConversion: payload.pointsConversion,
      isRebooked: payload.isRebooked,
    },
  });
};

export default { [addPaymentInfo]: emitAddPaymentInfo };

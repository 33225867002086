import React, { Fragment, useCallback } from 'react';
import styled from '@emotion/styled';
import { rem } from 'polished';
import { Flex, Text, Box, Container, Icon } from '@qga/roo-ui/components';
import { themeGet } from 'styled-system';
import { useSelector } from 'react-redux';
import { getPathName } from 'store/router/routerSelectors';
import { qantasBusinessRewards, qantasQff } from '@qga/roo-ui/logos';
import { DisclaimerItemLogo, DisclaimerText, DisclaimerLink } from 'components/Footer/DisclaimerItems';
import { useDataLayer } from 'hooks/useDataLayer';
import { getCampaignTermsAndConditions } from 'store/campaign/campaignSelectors';
import TravelInsuranceDisclaimer from './TravelInsuranceDisclaimer';
import {
  TRIP_ADVISOR_IMG_BRANDING_URL,
  QCOM_TERMS_AND_CONDITIONS_URL,
  POINTS_EARN_ENABLED,
  TRAVEL_INSURANCE_CROSS_SELL_ENABLED,
  TERMS_OF_USE_URL,
  AIRBNB_TAB_URL,
} from 'config';
import Markup from 'components/Markup';

const TripAdvisorLogo = styled.img`
  width: ${rem('120px')};
  height: ${rem('24px')};
  margin-left: ${themeGet('space.2')};
  margin-bottom: -${themeGet('space.1')};
`;

const savingDisclaimer =
  "~ Saving is off the hotel's generally available rate for the same property, room type, days, inclusions and conditions.";

const QFFDisclaimer =
  '±Qantas Frequent Flyer members can redeem Qantas Points when booking hotel accommodation through jetstar.com/hotels, using Points Plus Pay. Members cannot redeem points for additional charges paid to the hotel for extras (including cots, breakfasts and other incidentals) on check-in or check-out (as applicable). Points Plus Pay allows you to choose the number of Qantas Points you redeem above the specified minimum level of 5,000 and pay for the remainder of the booking value with an Accepted Payment Card (including VISA, Mastercard or American Express).';

const airbnbPointsDisclaimer =
  '++ 500 bonus Qantas Points will be awarded to Qantas Frequent Flyer members who make their first Airbnb booking. Qantas Frequent Flyer members will earn 1 Qantas Point per A$1 spent for all Airbnb stays booked through';

const Disclaimer = () => {
  const { emitInteractionEvent } = useDataLayer();

  const handleOnClickAirbnbLink = useCallback(() => {
    emitInteractionEvent({ type: 'Terms and Conditions', value: 'AirBnb Selected' });
  }, [emitInteractionEvent]);

  const pathName = useSelector(getPathName);
  const isConfirmationPage = pathName?.startsWith('/bookings');

  return (
    <Container pt={[8, 8, 15]} pb={[10, 10, 17]} gutter={[4, 6]} maxWidth={1284} bg="white" data-print-style="hidden">
      <Flex flexDirection="column" style={{ gap: '2.25rem' }}>
        <Text display="block" fontSize="base" fontWeight="bold" style={{ fontFamily: 'Jetstar' }}>
          Important information
        </Text>
        <Flex flexDirection={['column', 'column', 'row']} alignItems="flex-start" style={{ gap: '1rem' }}>
          <DisclaimerItemLogo
            src={qantasQff}
            alt="Qantas QFF Logo"
            role="img"
            aria-label="Qantas QFF Logo"
            style={{ height: 26, marginRight: 0 }}
          />
          <Flex flexDirection="column" style={{ gap: '2rem' }}>
            {POINTS_EARN_ENABLED ? (
              <QFFEarnDisclaimers />
            ) : (
              <Flex flexDirection="column" style={{ gap: '2rem' }}>
                <Flex data-testid="qff-disclaimer">
                  <DisclaimerText>
                    {QFFDisclaimer}&nbsp;{' '}
                    <DisclaimerLink data-testid="QFFDisclaimerT&Cs" href={TERMS_OF_USE_URL}>
                      View full terms and conditions here.
                    </DisclaimerLink>
                  </DisclaimerText>
                </Flex>
                <Flex data-testid="saving-disclaimer">
                  <DisclaimerText>{savingDisclaimer}&nbsp;</DisclaimerText>
                </Flex>
                <DisclaimerText data-testid="airbnb-points-disclaimer">
                  {airbnbPointsDisclaimer}&nbsp;
                  <DisclaimerLink href={AIRBNB_TAB_URL} onClick={handleOnClickAirbnbLink}>
                    jetstar.com/au/en/hotels
                  </DisclaimerLink>
                  .
                </DisclaimerText>
              </Flex>
            )}
            {isConfirmationPage && <Flex>{TRAVEL_INSURANCE_CROSS_SELL_ENABLED && <TravelInsuranceDisclaimer />}</Flex>}
            <Flex>
              <DisclaimerText data-testid="description-ratings-disclaimer-text">
                Descriptions and ratings featured are based on information provided by hotels and/or other service providers, distinguished
                accordingly. Ratings are either self-assigned by the service provider or obtained through Expedia (displayed as circles{' '}
                <Icon name="circle" color="brightSun" size={12} mb={rem('1px')} />
                <Icon name="circle" color="brightSun" size={12} mb={rem('1px')} />
                <Icon name="circle" color="brightSun" size={12} mb={rem('1px')} />
                ), or by independent third parties (displayed as stars <Icon name="star" color="brightSun" size={12} mb={rem('1px')} />
                <Icon name="star" color="brightSun" size={12} mb={rem('1px')} />
                <Icon name="star" color="brightSun" size={12} mb={rem('1px')} />) Any facilities shown as included are subject to change by
                the service provider. Jetstar does not guarantee any particular standard or class of accommodation or other service provided
                by a third party.
              </DisclaimerText>
            </Flex>
            <Flex>
              <DisclaimerText>
                † Includes fee payable in local currency direct to hotel. Prices in AUD are approx and based on today&#39;s exchange rate.
              </DisclaimerText>
            </Flex>
          </Flex>
        </Flex>
        <Flex>
          <DisclaimerText display="flex">
            Hotel ratings and reviews provided by
            <TripAdvisorLogo src={TRIP_ADVISOR_IMG_BRANDING_URL} alt="Trip-Advisor Logo" role="img" aria-label="Trip-Advisor Logo" />
          </DisclaimerText>
        </Flex>
      </Flex>
    </Container>
  );
};

const QFFEarnDisclaimers = () => {
  const { emitInteractionEvent } = useDataLayer();
  const campaignTermsAndConditions = useSelector(getCampaignTermsAndConditions);
  const defaultDisclaimerTermsAndConditions =
    'You must be a Qantas Frequent Flyer member to earn and redeem points. Membership and points are subject to the Qantas Frequent Flyer program terms and conditions.';
  const defaultTermsAndConditions =
    'Qantas Frequent Flyer members will earn 3 Qantas Points per A$1 value unless otherwise specified, for hotel stays booked through qantas.com/hotels, except Classic Hotel Rewards and Airbnb bookings. Points Club members will earn 25% more Qantas Points, and Points Club Plus members will earn 50% more Qantas Points. Qantas Points will be credited to your account at least 8 weeks after check-out. Qantas Points may be earned by the member in whose name the booking is made. Members will not be able to earn points on additional charges paid to the accommodation provider for extras (including cots, breakfasts and other incidentals) on check-in or check-out (as applicable).';
  const pointsPlusPayTermsAndConditions =
    '* Qantas Frequent Flyer members can redeem Qantas Points when booking hotel accommodation through qantas.com/hotels or holiday packages through qantas.com/holidays, using Points Plus Pay. Members cannot redeem points for additional charges paid to the hotel for extras (including cots, breakfasts and other incidentals) on check-in or check-out (as applicable). Points Plus Pay allows you to choose the number of Qantas Points you redeem above the specified minimum level of 5,000 and pay for the remainder of the booking value with an Accepted Payment Card (including VISA, MasterCard or American Express). Points Plus Pay is not available for Classic Hotel Rewards.';
  const termsAndConditions = campaignTermsAndConditions ? defaultTermsAndConditions : `^ ${defaultTermsAndConditions}`;
  const handleOnClickPointsPlusPayLink = useCallback(() => {
    emitInteractionEvent({ type: 'Points Plus Pay Conditions', value: 'View Full Terms and Conditions Link Selected' });
  }, [emitInteractionEvent]);
  const handleOnClickBusinessRewardLink = useCallback(() => {
    emitInteractionEvent({ type: 'Business Rewards Conditions', value: 'Conditions Apply Link Selected' });
  }, [emitInteractionEvent]);
  const handleOnClickAirbnbLink = useCallback(() => {
    emitInteractionEvent({ type: 'Terms and Conditions', value: 'AirBnb Selected' });
  }, [emitInteractionEvent]);
  return (
    <Fragment>
      <Flex flexDirection={['column', 'row']} alignItems="flex-start">
        <DisclaimerItemLogo src={qantasQff} alt="Qantas QFF Logo" role="img" aria-label="Qantas QFF Logo" />
        <DisclaimerText data-testid="points-disclaimer-text">
          {campaignTermsAndConditions && <Markup content={`^ ${campaignTermsAndConditions} &nbsp;`} />}
          <Box mt={2} data-testid="disclaimer-terms-and-conditions">
            {defaultDisclaimerTermsAndConditions}&nbsp;
          </Box>
          <Box mt={2} data-testid="terms-and-conditions">
            {termsAndConditions} &nbsp;
          </Box>
          <Box mt={2} data-testid="points-plus-pay-t&c">
            {pointsPlusPayTermsAndConditions} &nbsp;
            <DisclaimerLink
              data-testid="pointsPlusPayViewFullTermsAndConditionsLink"
              href={QCOM_TERMS_AND_CONDITIONS_URL}
              onClick={handleOnClickPointsPlusPayLink}
            >
              View full terms and conditions here
            </DisclaimerLink>
          </Box>
          <Box mt={2} data-testid="saving-disclaimer">
            {savingDisclaimer}&nbsp;
          </Box>
          <Box data-testid="airbnb-points-disclaimer">
            {airbnbPointsDisclaimer}&nbsp;
            <DisclaimerLink href={AIRBNB_TAB_URL} onClick={handleOnClickAirbnbLink}>
              jetstar.com/au/en/hotels
            </DisclaimerLink>
            .
          </Box>
        </DisclaimerText>
      </Flex>
      <Flex flexDirection={['column', 'row']} alignItems="flex-start">
        <DisclaimerItemLogo
          src={qantasBusinessRewards}
          alt="Qantas Business Reward Logo"
          role="img"
          aria-label="Qantas Business Reward Logo"
        />
        <DisclaimerText>
          ** Businesses earn 1 Qantas Point per AU$1 spent on eligible Qantas Hotels worldwide. Eligible Qantas hotels excludes Classic
          Hotel Rewards. A business must be a Qantas Business Rewards Member to earn Qantas Points for your business. Qantas Points are not
          earned on any amounts payable directly to the hotel. &nbsp;
          <DisclaimerLink
            data-testid="businessRewardConditionApplyLink"
            href={QCOM_TERMS_AND_CONDITIONS_URL}
            onClick={handleOnClickBusinessRewardLink}
          >
            Conditions apply
          </DisclaimerLink>
        </DisclaimerText>
      </Flex>
    </Fragment>
  );
};

export default Disclaimer;

import { createSelector } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-named-as-default
import Decimal from 'decimal.js';
import isEmpty from 'lodash/isEmpty';
import every from 'lodash/every';
import { getQuote } from 'store/quote/quoteSelectors';
import { getFormData } from 'store/checkout/checkoutSelectors';
import { getDomesticOrInternational, getStateCodeFromStateName, getNumNights, getTransactionPointsPlusPay } from 'lib/datalayer/helpers';

const getCheckoutVariables = (quote) => {
  const ready = !!quote;
  const country = quote?.property?.address?.country;
  const domesticOrInternational = getDomesticOrInternational(country);
  const checkIn = quote?.stay?.checkIn;
  const checkOut = quote?.stay?.checkOut;

  return {
    ready,
    domesticOrInternational,
    propertyName: quote?.property?.name,
    propertyId: quote?.property?.id,
    propertyCountryName: country,
    propertyStateCode: getStateCodeFromStateName(quote?.property?.address?.state),
    propertyCity: quote?.property?.address?.suburb,
    checkIn,
    checkOut,
    roomNights: getNumNights(checkIn, checkOut),
    numAdults: quote?.stay?.adults,
    numChildren: quote?.stay?.children,
    numInfants: quote?.stay?.infants,
    bookingTotal: quote?.offer?.charges?.total?.amount,
    roomType: quote?.roomType?.name,
    // These keys are pluralised so that they align with the variables from the property page in GA
    promotionNames: quote?.offer?.promotion?.name ?? 'none',
    promotionCodes: quote?.offer?.promotion?.promotionCode ?? 'none',
  };
};

const getTransactionDetails = (quote, formData) => {
  // Wait until points (async calc) is returned. Even 0 point transactions will be Decimal(0) and not undefined
  // Quote will be undefined if user navigates back to property page from checkout
  if (isEmpty(quote) || isEmpty(formData?.payments?.points?.amount)) {
    return { ready: false };
  }

  const transactionTotal = quote?.offer?.charges?.total?.amount;

  const checkIn = quote?.stay?.checkIn;
  const checkOut = quote?.stay?.checkOut;
  const numNights = getNumNights(checkIn, checkOut);
  const averageNightlyRate = new Decimal(transactionTotal).dividedBy(numNights).toFixed(2);

  const transactionCash = new Decimal(formData?.payments?.cash?.payableNow?.amount ?? '0.0');
  const transactionPoints = new Decimal(formData?.payments?.points?.amount ?? '0.0');
  const transactionVoucher = new Decimal(formData?.payments?.voucher?.amount ?? '0.0');

  const transactionIncludesVoucher = !transactionVoucher.isZero();
  const transactionPointsPlusPay = getTransactionPointsPlusPay(transactionCash, transactionPoints);

  const earnedPoints = quote?.offer?.pointsEarned?.qffPoints?.total;
  const payInPointsPercentage = transactionCash.dividedBy(transactionTotal).times(100).minus(100).abs().toFixed(2);

  return {
    ready: true,
    transactionPoints: transactionPoints.toString(),
    transactionTotal,
    transactionPointsPlusPay,
    transactionIncludesVoucher,
    averageNightlyRate,
    earnedPoints,
    payInPointsPercentage,
  };
};

export const getCheckoutPageDataLayerVariables = createSelector(getQuote, getFormData, (quote, formData) => {
  const checkoutVariables = getCheckoutVariables(quote);
  const transactionDetails = getTransactionDetails(quote, formData);
  const parts = [checkoutVariables, transactionDetails];

  return {
    ...checkoutVariables,
    ...transactionDetails,
    ready: every(parts, { ready: true }),
  };
});

import { viewPromotion } from 'store/campaign/campaignActions';
import { EventType, createEvent } from '@qantasexperiences/analytics';

const emitViewPromotion = ({ payload }) => {
  return createEvent({
    type: EventType.VIEW_PROMOTION,
    payload: {
      promotion: payload.promotion,
    },
  });
};

// eslint-disable-next-line
// @ts-ignore
export default { [viewPromotion]: emitViewPromotion };

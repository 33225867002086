import get from 'lodash/get';
import { createSelector } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-named-as-default
import Decimal from 'decimal.js';
import pick from 'lodash/pick';
import { dateFromString } from 'lib/date';

export const getIsLoading = (state) => get(state, 'quote.isLoading');
export const getQuote = (state) => get(state, 'quote.quote');
export const getQuoteError = (state) => get(state, 'quote.error');
export const getQuoteReference = (state) => get(state, 'quote.quote.stay.quoteReference');
export const getPropertyId = (state) => get(state, 'quote.quote.property.id');
export const getIsDepositPayAvailable = (state) => get(state, 'quote.quote.offer.depositPay.depositPayable');

export const getPayableLaterDueDateString = (state) => state?.quote?.quote?.offer?.depositPay?.balancePaymentDue;

export const getPayableLaterDueDate = (state) => {
  const dueDate = get(state, 'quote.quote.offer.depositPay.balancePaymentDue');
  return dueDate ? new Date(dueDate) : null;
};

export const getProperty = (state) => get(state, 'quote.quote.property');
export const getCancellationPolicy = (state) => get(state, 'quote.quote.offer.cancellationPolicy');

export const getStayDates = createSelector(getQuote, (quote) => {
  const checkIn = get(quote, 'stay.checkIn');
  const checkOut = get(quote, 'stay.checkOut');

  return {
    checkIn: dateFromString(checkIn),
    checkOut: dateFromString(checkOut),
  };
});

export const getOccupants = createSelector(getQuote, (quote) => pick(get(quote, 'stay', {}), ['adults', 'children', 'infants']));

export const getCharges = createSelector(getQuote, (quote) => get(quote, 'offer.charges', {}));

export const getIsClassic = createSelector(getQuote, (quote) => get(quote, 'offer.type') === 'classic');

export const getIsAvailable = createSelector(getQuote, (quote) => get(quote, 'status') === 'AVAILABLE');

export const getTotalPayableAtBooking = createSelector(
  getQuote,
  (quote) => new Decimal(get(quote, 'offer.charges.payableAtBooking.total.amount') || 0),
);

export const getTotalPayableAtBookingInPoints = createSelector(
  getQuote,
  (quote) => new Decimal(get(quote, 'offer.charges.payableAtBooking.total.points') || 0),
);

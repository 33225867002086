import { AIRBNB_INTERSTITIAL_PAGE_URL } from 'config';
import { useIsAuthenticated } from 'lib/oauth';
import { useOAuthState } from 'lib/oauth/useOAuthState';
import { useLoginUrl } from 'lib/qffAuth';
import { useRouter } from 'next/router';

export default function useAirbnbRedirect() {
  const router = useRouter();
  const isAuthenticated = useIsAuthenticated();
  const { loginUrl } = useLoginUrl();
  const [state] = useOAuthState();

  const handleAirbnbRedirect = (query: string) => {
    if (!isAuthenticated) {
      const url = JSON.stringify(`/airbnb/redirect?${query}`);
      window.localStorage.setItem(state, url);
      router.push(loginUrl);
    } else {
      router.push(`${AIRBNB_INTERSTITIAL_PAGE_URL}?${query}`);
    }
  };

  return { handleAirbnbRedirect };
}
